@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
    font-size: 60%;
    --color-background: #656565;
    --color-background-dark: #141414;
    --color-text-title: #ffffff;
    --color-text-title-shadow: #d5d5d5;
    --color-text-shadow: #d5d5d5; 
    --color-text-base: #ffffff;   
    --color-text-hover: #d5d5d5;
    --color-text-secondary: #9b9b9b;
    --color-text-secondary-shadow: #656565;
    --color-button: #989898;
}

* {
    margin: 0;
    padding: 0;    
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: var(--color-background-dark);   
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body,
input,
button,
textarea {
    font: 500 1.6rem barlow;
    color: var(--color-text-base);
}

.container {
    width: 90vw;    
}

.footer-background {
    background: var(--color-text-hover);
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}