#error-page {
	width: 100vw;
  height: 100vh;
  overflow-x: hidden; 
}

.error-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-background-dark);
}

.error-container h2 {
  color: var(--color-text-base);
  margin-bottom: 5rem;
  text-align: center
}

.error-container h3 {
  color: var(--color-text-base);
  text-align: center;
}

