.main {
  height: 55rem;  
  background: var(--color-background-dark);  
}

.main .main-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../../../assets/images/mobile.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.main .main-content .logo {
  width: 28rem;
  height: 28rem; 
}

.main .main-content .logo img {
  width: 100%;
  height: 100%;
}

.main .main-content .text-block {
  width: 90%;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.main .main-content .text-block h1 {
  font: 600 2.5rem barlow;
}

.main .main-content .text-block .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.main .main-content .text-block .description strong {
  margin-top: 0.5rem;
  font: 500 1.5rem barlow;
}

@media (min-width: 600px) {
  .main {
    height: 80rem;   
  }

  .main .main-content {
    background-image: url('../../../assets/images/background_mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  
  
  .main .main-content {
    justify-content: flex-start;
  }
  
  .main .main-content .logo {
    width: 35rem;
    height: 35rem;   
  }
  
  .main .main-content .text-block {
     height: 25rem;
  }

  .main .main-content .text-block h1 {
    font: 600 4rem barlow;
  }
  
  .main .main-content .text-block .description strong {
   font: 500 2.2rem barlow;
  }
}

@media (min-width: 1200px) {
  .main {
    height: 80rem;    
  }
  
  .main .main-content {
    background-image: url('../../../assets/images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom right;
    justify-content: space-between;
    align-items: start;
  }

  .main .main-content .logo {
    width: 40rem;
    height: 40rem;
    margin-left: 6rem;
  }

  .main .main-content .text-block {
    width: 80rem;
    height: 40rem;
    align-items: flex-start;
    margin-left: 6rem;
  }

  .main .main-content .text-block h1 {
    font: 700 5.8rem barlow;
  }

  .main .main-content .text-block .description {
    flex-direction: row;
  }

  .main .main-content .text-block .description strong {
    font: 500 3rem barlow;
    margin-right: 1rem;
  }
}

@media (min-width: 1500px) {
  .main .main-content .logo {
    margin-left: 15rem;
  }

  .main .main-content .text-block {
   margin-left: 15rem;
  }
}