.banner {
  width: 100%;
  height: 40rem;  
  background: var(--color-background-dark);  
}

.banner .banner-content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
}

.banner .banner-content h2 {
  font: 600 2.2rem barlow;
}

.banner .banner-content strong {
  margin-top: 5rem;
  margin-bottom: 2rem;
  font: 600 3rem barlow;
  color: var(--color-text-secondary);
  text-shadow: 1px 1px var(--color-text-secondary-shadow);
}

.banner .banner-content .seta {
  width: 8rem;
  height: 8rem;
}

.banner .banner-content .seta img {
  width: 100%;
  height: 100%;
}

@media (min-width:600px) {
  .banner .banner-content h2 {
    font: 600 3rem barlow;
  }  
}

@media (min-width: 1200px) {
  .banner {
    height: 50rem;
  }

  .banner .banner-content h2 {
    font: 600 4.5rem barlow;
  } 
}